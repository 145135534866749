import { useEffect } from 'react';
import { useTrackOnce } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import { FIND_STATUS } from '../constants';

const useOneTouchSwitchTrackEvents = (state, firstAttemptFailed) => {
  const trackBroadbandNotFoundInfoDisplayed = useTrackOnce();
  const trackFirstTrySuccessModalDisplayed = useTrackOnce();
  const trackSecondTrySuccessModalDisplayed = useTrackOnce();

  useEffect(() => {
    // Track for success modal after the first attempt, without adding the accountReference
    if (
      firstAttemptFailed === false &&
      state.findStatus === FIND_STATUS.FOUND &&
      !state.accountReference
    ) {
      trackFirstTrySuccessModalDisplayed(
        EVENTS.OTS_FIRST_TRY_SUCCESS_MODAL_DISPLAYED
      );
    }
    // Track for success modal after the second attempt, with the accountReference added
    if (
      firstAttemptFailed === true &&
      state.findStatus === FIND_STATUS.FOUND &&
      state.accountReference
    ) {
      trackSecondTrySuccessModalDisplayed(
        EVENTS.OTS_SECOND_TRY_SUCCESS_MODAL_DISPLAYED
      );
    }

    if (state.findStatus === FIND_STATUS.FAIL) {
      trackBroadbandNotFoundInfoDisplayed(
        EVENTS.OTS_BROADBAND_NOT_FOUND_INFO_DISPLAYED
      );
    }
  }, [
    state.findStatus,
    state.accountReference,
    firstAttemptFailed,
    trackBroadbandNotFoundInfoDisplayed,
    trackFirstTrySuccessModalDisplayed,
    trackSecondTrySuccessModalDisplayed,
  ]);
};

export { useOneTouchSwitchTrackEvents };
