const YES_NO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
const FIND_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  FINDING: 'FINDING',
  FOUND: 'FOUND', // match success, modal open
  SUCCESS: 'SUCCESS', // match success, modal closed
  NOT_FOUND: 'NOT_FOUND', // not found, can retry
  FAIL: 'FAIL', // not found, cannot retry
};

const INPUTS_MAX_WIDTH_PX = 320;

export { YES_NO_OPTIONS, INPUTS_MAX_WIDTH_PX, FIND_STATUS };
