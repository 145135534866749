import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { func, bool, objectOf, shape, string } from 'prop-types';
import { debounce, noop } from 'lodash';

import { BasketContainer } from 'app/redux/modules/YourDetails/containers/BasketContainer';
import { DirectDebitSectionContainer } from 'app/redux/modules/YourDetails/containers/DirectDebitSectionContainer';
import { ReferralAndLegalFormSectionContainer } from 'app/redux/modules/YourDetails/containers/ReferralAndLegalFormSectionContainer';
import { OneTouchSwitchContainer } from 'app/redux/modules/YourDetails/containers/OneTouchSwitchContainer';
import AboutYouContainer from 'app/redux/modules/YourDetails/containers/AboutYou';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import SectionCentered from 'modules/layout/SectionCentered';

import { FormErrorSnackbar } from './FormErrorSnackbar';
import { useStyles } from './styles';
import { formErrorsPropType } from './propTypes';
import { SubmitDeniedDialog } from './AboutYouSection/SubmitDeniedDialog';
import { useScrollInputErrorIntoView } from 'app/hooks/useScrollTopInputError';
import { ORDERED_FORM_FIELDS } from 'app/redux/modules/YourDetails/form';

const useScrollToError = ({ validationErrors, shouldScroll, onScrollEnd }) => {
  const scrollToError = useScrollInputErrorIntoView(
    ORDERED_FORM_FIELDS,
    validationErrors
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleScroll = useCallback(
    debounce(() => {
      scrollToError();
      onScrollEnd();
    }, 1000),
    []
  );

  useEffect(() => {
    if (shouldScroll) {
      debouncedHandleScroll();
    }
  }, [debouncedHandleScroll, shouldScroll]);
};

const YourDetails = ({
  formErrors,
  handleDeniedDialogClose,
  isDeniedDialogOpen,
  isFormValid,
  isLoading,
  getFormInitialData,
  onSubmit,
  onErrorSnackClose,
  onScrollEnd,
  shouldScroll,
  validateForm,
  validationErrors,
  isOneTouchSwitchComplete,
  isOneTouchSwitchEligible,
  hasBroadband,
  clearOTSForm,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  useScrollToError({ validationErrors, shouldScroll, onScrollEnd });
  const [
    hasDisplayedTermsAndConditions,
    setHasDisplayedTermsAndConditions,
  ] = useState(false);
  const referralAndLegalRef = useRef(null);
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    // 1. Broadband contract generation is triggered by this endpoint
    // It ensures that contract will be re ady once user click the link
    // 2. Credit check information is carried by this request
    getFormInitialData();
  }, [getFormInitialData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  useEffect(() => {
    if (
      isOneTouchSwitchComplete &&
      referralAndLegalRef.current &&
      !hasDisplayedTermsAndConditions
    ) {
      referralAndLegalRef.current.scrollIntoView({ behavior: 'smooth' });
      setHasDisplayedTermsAndConditions(true);
    }
  }, [isOneTouchSwitchComplete]);

  useEffect(() => {
    renderReferralAndLegalSection();
  }, [hasDisplayedTermsAndConditions]);

  const renderReferralAndLegalSection = () => {
    if (
      !hasBroadband ||
      hasDisplayedTermsAndConditions ||
      !isOneTouchSwitchEligible
    ) {
      return <ReferralAndLegalFormSectionContainer />;
    }

    if (isOneTouchSwitchComplete) {
      return (
        <div ref={referralAndLegalRef}>
          <ReferralAndLegalFormSectionContainer />
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    // don't persist OTS form across navigations
    clearOTSForm();
  }, []);

  return (
    <>
      <FormErrorSnackbar errors={formErrors} onClose={onErrorSnackClose} />
      <form className={classes.form} onSubmit={handleSubmit}>
        <SectionCentered backgroundColor="grey">
          <div className={classes.wrapper}>
            <div className={classes.formColumn}>
              <AboutYouContainer />
              <DirectDebitSectionContainer />
              {hasBroadband && isOneTouchSwitchEligible && (
                <OneTouchSwitchContainer />
              )}
              {renderReferralAndLegalSection()}
            </div>
            {!isMobile && <BasketContainer />}
          </div>
        </SectionCentered>
        {isMobile && <BasketContainer isMobile />}
        <NavigationPane
          back
          next
          nextLabel="Confirm order"
          nextType="submit"
          nextHandler={noop}
          nextDisabled={isLoading || !isFormValid}
          loading={isLoading}
        />
      </form>
      <SubmitDeniedDialog
        open={isDeniedDialogOpen}
        onClose={handleDeniedDialogClose}
      />
    </>
  );
};

YourDetails.propTypes = {
  formErrors: formErrorsPropType,
  handleDeniedDialogClose: func.isRequired,
  isDeniedDialogOpen: bool.isRequired,
  isFormValid: bool.isRequired,
  isLoading: bool.isRequired,
  getFormInitialData: func.isRequired,
  onSubmit: func.isRequired,
  onErrorSnackClose: func.isRequired,
  onScrollEnd: func.isRequired,
  shouldScroll: bool.isRequired,
  validateForm: func.isRequired,
  validationErrors: objectOf(
    shape({
      field: string.isRequired,
      valid: bool.isRequired,
      reason: string,
    })
  ),
  hasBroadband: bool.isRequired,
  isOneTouchSwitchComplete: bool.isRequired,
  isOneTouchSwitchEligible: bool.isRequired,
  clearOTSForm: func.isRequired,
};

export { YourDetails };
