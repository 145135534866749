// logic here derived from
// https://github.com/utilitywarehouse/telecom-fixed-line/blob/main/pkg/address/pafots.go#L29

const formatCustomerInputAddress = (address) => {
  if (address?.paf) {
    return address.paf;
  }
  const customerPaf = {
    buildingName: address.buildingName || '',
    buildingNumber: address.buildingNumber || '',
    thoroughfare: address.street || '',
    postTown: address.town || '',
    postcode: address.postcode || '',
    subBuilding: address.flatNumber || '',
    doubleDependentLocality: '',
    dependentLocality: '',
  };
  return customerPaf;
};

const formatAddressLines = (address) => {
  let paf;
  const addresHasPaf = !!address.paf;
  if (addresHasPaf) {
    paf = address.paf;
  }
  if (address.addressNotListed) {
    paf = formatCustomerInputAddress(address);
  }
  const addressLines = [paf.subBuilding];
  if (/^\d/.test(paf.buildingName)) {
    const line = [];

    line.push(paf.buildingName);

    if (paf.dependentThoroughfare) {
      line.push(paf.dependentThoroughfare);
    } else if (paf.thoroughfare) {
      line.push(paf.thoroughfare);
    }

    addressLines.push(line.join(' '));
  } else {
    addressLines.push(paf.buildingName);
    const line = [];

    if (paf.buildingNumber) {
      line.push(paf.buildingNumber);
    }
    if (paf.dependentThoroughfare) {
      line.push(paf.dependentThoroughfare);
    } else if (paf.thoroughfare) {
      line.push(paf.thoroughfare);
    }

    addressLines.push(line.join(' '));
  }

  addressLines.push(paf.doubleDependentLocality);
  addressLines.push(paf.dependentLocality);
  return addressLines.filter(Boolean);
};

export { formatAddressLines, formatCustomerInputAddress };
