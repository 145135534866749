import React, { useState, useEffect } from 'react';
import {
  defaultOTSState,
  FindStatus,
  SwitchTile,
} from '@utilitywarehouse/quote-ui';
import PropTypes from 'prop-types';
import {
  oneTouchSwitchSection,
  referralAndLegalSection,
} from 'app/redux/modules/YourDetails/form';
import {
  formatAddressLines,
  formatCustomerInputAddress,
} from './formatAddress';

import { useOneTouchSwitchTrackEvents } from './useOneTouchSwitchTrackEvents';
import { FIND_STATUS } from '../constants';
const useOneTouchSwitchState = (
  fields,
  updateForm,
  termsAndConditionsState,
  hasBroadband,
  supplyAddress,
  surname,
  orderType,
  numberToPort,
  isSurnameValid,
  updateBothForms,
  hasControl,
  updateLandlineNumber
) => {
  const {
    HAS_EXISTING_LINE,
    CURRENT_PROVIDER,
    PROVIDER_NOT_LISTED,
    FIND_STATUS,
    MATCH_ID,
    SWITCHING_REFERENCE,
    BROADBAND_REFERENCE,
    ...otherOtsData
  } = oneTouchSwitchSection;
  const [rawState, setRawState] = useState({
    ...defaultOTSState,
    surname,
    currentAddress: {
      uprn: supplyAddress.uprn,
      addressLines: formatAddressLines(supplyAddress),
      postCode: formatCustomerInputAddress(supplyAddress).postcode,
      postTown: formatCustomerInputAddress(supplyAddress).postTown,
    },
    orderType: orderType,
    numberToPort: numberToPort,
    canSubmit: isSurnameValid,
  });

  const handleFormUpdate = (field, value) => {
    if (termsAndConditionsState) {
      updateBothForms(
        [{ field, value }],
        [{ field: referralAndLegalSection.TERMS_AND_CONDITIONS, value: false }]
      );
    } else {
      updateForm(field, value);
    }
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      canSubmit: isSurnameValid,
    }));
  }, [isSurnameValid]);

  // Update `surname` based on the new `surname` prop
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      surname: surname,
    }));
  }, [surname]);
  // update form validation redux
  useEffect(() => {
    if (!hasBroadband) {
      updateForm(HAS_EXISTING_LINE, false);
      setState((prevState) => ({
        ...prevState,
        hasExistingLine: false,
      }));
    }
  }, [hasBroadband, updateForm, HAS_EXISTING_LINE]);

  useEffect(() => {
    if (
      rawState.homePhoneReference &&
      rawState.findStatus === FindStatus.SUCCESS
    ) {
      const newLandLine = rawState.homePhoneReference.toString();
      updateLandlineNumber(newLandLine);
    }
  }, [rawState]);

  const setState = (state) => {
    const {
      hasExistingLine,
      selectedProvider,
      providerNotListed,
      ...otherState
    } = state;

    handleFormUpdate(HAS_EXISTING_LINE, hasExistingLine);
    handleFormUpdate(CURRENT_PROVIDER, selectedProvider);
    handleFormUpdate(PROVIDER_NOT_LISTED, providerNotListed);

    for (const [key, value] of Object.entries(otherState)) {
      updateForm(key, value);
    }

    setRawState(state);
  };

  return [
    {
      ...rawState,
      ...otherOtsData,
      hasExistingLine: fields[HAS_EXISTING_LINE] ?? true,
      providerNotListed: fields[PROVIDER_NOT_LISTED] ?? false,
      selectedProvider: fields[CURRENT_PROVIDER] ?? null,
      findStatus: fields[FIND_STATUS] ?? FindStatus.NOT_STARTED,
      matchId: fields[MATCH_ID] ?? null,
      switchingReference: fields[SWITCHING_REFERENCE] ?? null,
      broadbandReference: fields[BROADBAND_REFERENCE] ?? '',
      hasControl,
    },
    setState,
  ];
};

const OneTouchSwitch = ({
  fields,
  updateForm,
  supplyAddress,
  termsAndConditionsState,
  surname,
  hasBroadband,
  orderType,
  numberToPort,
  isSurnameValid,
  updateBothForms,
  isRemote,
  isDriver,
  updateLandlineNumber,
}) => {
  const hasControl = isRemote ? isDriver : true;
  const [state, setState] = useOneTouchSwitchState(
    fields,
    updateForm,
    termsAndConditionsState,
    hasBroadband,
    supplyAddress,
    surname,
    orderType,
    numberToPort,
    isSurnameValid,
    updateBothForms,
    hasControl,
    updateLandlineNumber
  );

  const onContinue = () => {
    // eslint-disable-next-line
    console.log('DEV');
  };
  const [firstAttemptFailed, setFirstAttemptFailed] = useState(false);

  useEffect(() => {
    if (state.findStatus === FIND_STATUS.NOT_FOUND) {
      setFirstAttemptFailed(true);
    }
  }, [state.findStatus]);

  useOneTouchSwitchTrackEvents(state, firstAttemptFailed);
  return (
    <SwitchTile state={state} setState={setState} onContinue={onContinue} />
  );
};

OneTouchSwitch.propTypes = {
  updateForm: PropTypes.func.isRequired,
  fields: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  supplyAddress: PropTypes.shape({
    firstLine: PropTypes.string,
    postcode: PropTypes.string,
  }).isRequired,
  onTermsChecked: PropTypes.func.isRequired,
  termsAndConditionsState: PropTypes.bool,
  hasBroadband: PropTypes.bool.isRequired,
  surname: PropTypes.string.isRequired,
  orderType: PropTypes.string,
  numberToPort: PropTypes.bool || PropTypes.string,
  updateBothForms: PropTypes.func.isRequired,
  updateLandlineNumber: PropTypes.func.isRequired,
};

export default OneTouchSwitch;
